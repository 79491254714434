
import { defineComponent, onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// @import utils
import { IUserLevel } from '@/core/data/userLevel';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
// @import components
import PlayListModal from '@/components/modals/forms/PlayListModal.vue';
import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';
import PlayListRuleModal from '@/components/modals/forms/PlayListRuleModal.vue';
import TemplateContentModal from '@/components/modals/forms/TemplateContentModal.vue';
import { IEnumerationFields, IEnumerationTypes } from '@/core/data/enumerationTypes';
import { IEnumerationValues } from '@/core/data/enumerationValues';

export default defineComponent({
	name: 'Update-Video-Content',
	components: {
		PlayListModal,
		PlayListRuleModal,
		PricePlanModal,
		VideoContentModal,
		TemplateContentModal
	},
	setup() {
		const route = useRoute();
		const store = useStore();

		const imageModel = ref<any>(null);
		const contentEditor = ref<any>(null);
		const descriptionEditor = ref<any>(null);
		const formRef = ref<null | HTMLFormElement>(null);

		const loading = ref<boolean>(false);
		const videoContentArray = ref<any>([]);
		const detailImageUrl = ref<string>('');
		const overviewImageUrl = ref<string>('');
		const selectedPricingPlans = ref<any>([]);
		const userLevel = ref<Array<IUserLevel>>([]);
		const relatedContentsExpanded = ref<any>([]);
            
		const selectedRule = ref<any>(null);
		const isEdit = route.params.id;
		
		let enumerationFields = ref<Array<IEnumerationFields>>([]);
        let selectedEnumeration = ref<IEnumerationFields>();
		let selectedEnumerationValue = ref<IEnumerationValues>();

		var sample: {
			id: string,
            name: string,
            contentType: string,
            sortRule: string,
            matchValueToAllFilters: boolean,
            enumerationValues: Array<IEnumerationValues>
			isRelatedRule: boolean,
			enumerationId: string
		} = {
			id: '',
            name: "",
            contentType: "",
            sortRule: "",
            matchValueToAllFilters: true,
            enumerationValues: [],
			isRelatedRule: false,
			enumerationId: ""
		}

		const formData = ref(sample);

		const rules = ref({});

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} PlaylistRule`, [
				'PlaylistRule'
			]);
			if (isEdit) {
				getPlayListRuleById();
			}
		});

        const OnContentChange = (event) =>
		{
			console.log("content changed")
			formData.value.enumerationValues = [];
			enumerationFields.value = [];

			getEnumerationsType();
		}

		const OnEnumerationValueChange = (event) =>
        {
			if(selectedEnumerationValue.value)
			{
				if(selectedEnumeration.value)
				{
					selectedEnumerationValue.value.enumeration = {
						id: 0,
						customerId: "",
						enumName: selectedEnumeration.value.displayName,
						sortOrder: 0
					};
				}

				formData.value.enumerationValues.push(selectedEnumerationValue.value)
			}

			selectedEnumerationValue.value = undefined;
        }

        const getEnumerationsType = async () => {
			var url = 'TabFields/' + formData.value.contentType.toLocaleLowerCase();

			await ApiService.get(url)
				.then(({ data }) => {

					data.map(et => enumerationFields.value = enumerationFields.value.concat(et.fields.filter(f => f.fieldType != "Text")))
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const getPlayListRuleById = () => {
			ApiService.get(`PlayListRule/${route.params.id}`)
				.then(({ data }) => {
                    formData.value = {
						...formData.value,
						...data
					};
                    getEnumerationsType();
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const resetEnumerationValue = (id) => {
			formData.value.enumerationValues = formData.value.enumerationValues.filter(x => x.id != id)
		}

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					const data: any = {
						...formData.value,
                        enumerationValues: formData.value.isRelatedRule? [] : formData.value.enumerationValues.map(({id}) => id),
						enumerationId: formData.value.isRelatedRule? selectedEnumeration.value?.enumReference : null
					};

					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(
							`PlayListRule/${route.params.id}`,
							data
						);
					} else {
						delete data['id'];
						serviceType = ApiService.post('PlayListRule', data);
					}

					serviceType
						.then(({ data }) => {
							// console.log('create VideoContent response', data);
							loading.value = false;
							// redirect to main page ?
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (!isEdit) 
                                {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create video content error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			rules,
			moment,
			isEdit,
			submit,
			selectedRule,
            selectedEnumeration,
			selectedEnumerationValue,
			formRef,
			loading,
			formData,
            OnContentChange,
			OnEnumerationValueChange,
			resetEnumerationValue,
			enumerationFields,
			userLevel,
			imageModel,
			contentEditor,
			detailImageUrl,
			overviewImageUrl,
			videoContentArray,
			descriptionEditor,
			selectedPricingPlans,
			relatedContentsExpanded
		};
	}
});
